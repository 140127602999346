@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body{
  background: #181818;
  font-family: 'Open Sans', sans-serif;
  font: 20px/28px ' Sans', sans-serif;
  font-size: 16px;
  font-size: 1.5vw;
}

label { 
  resize: none;
  font: 20px/28px ' Sans', sans-serif;
  color: white;
  text-align:center;
}

table {
  width: 98%;
  margin-left: 20px;
  border-collapse: collapse;
  text-align:left;
}

th {
  color: white;
  padding-bottom: 10px;
}

td {
  border-top: 2px solid #7A7A7A;
  color: white;
}

.user_image {
  border-radius: 50%;
}

tr:hover {background-color: #000000;}

select {
  background-color: #4e4e4e;
  outline-color: white;
  color: white;
  resize: none;
  font: 20px/28px ' Sans', sans-serif;
  border: none;
}

.btn-profile {
  background-image: "public/spotify.png";
}

.column {
  float: left;
  width: 20%;
  padding: 10px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
  background-color: #1fd85e;
}

.t-center {
  text-align:center;
  font: 28px/28px ' Sans', sans-serif;
  cursor:pointer;
}

.t-left {
  text-align:left;
  font: 28px/28px ' Sans', sans-serif;
  cursor:pointer;
}

.row-centered {
  text-align:center;
}

.about {
  top: 10px; right: 10px;
  position: absolute;
  background-color: #1fd85e; /* Green background */
  padding: 5px 5px; /* Some padding */
  display: block;
  font-size: 14px;
  color: #000000;
  font: 14px/16px ' Sans', sans-serif;
  border-radius: 5px 5px 5px 5px;
  border: 3px solid #000000;
}

.goButton {
  width: 40px;
  height: 40px;
  border: 3px solid #000000;
  background: #1fd85e;
  text-align: center;
  color: #000000;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  font-size: 20px;
  margin: auto;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

